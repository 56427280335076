(<template>
  <div class="grouped-by-list">
    <h4 class="grouped-by-list__subtitle">{{ subtitle }}</h4>
    <template v-if="hasData">
      <div v-for="(department, index) in groupedByList"
           :key="index"
           class="grouped-by-list__wrapper">
        <p>{{ department.label }}</p>
        <span>{{ department.value }}</span>
      </div>
    </template>
    <div v-else
         class="grouped-by-list__empty">
      <h5 class="grouped-by-list__empty-subtitle">{{ subtitleEmpty }}</h5>
      <p>{{ descriptionEmpty }}</p>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      groupedByList: {
        type: Array,
        default: () => []
      },
      subtitle: {
        type: String,
        default: ''
      },
      subtitleEmpty: {
        type: String,
        default: ''
      },
      descriptionEmpty: {
        type: String,
        default: ''
      }
    },
    computed: {
      hasData() { return this.groupedByList.length > 0; }
    }
  };
</script>

<style scoped>
.grouped-by-list {
  overflow-y: auto;
  width: calc(50% - 12px);
  max-height: 400px;
  padding: 20px 24px 32px 24px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
}

.grouped-by-list__subtitle {
  margin-bottom: 8px;
  font-size: 18px;
}

.grouped-by-list__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #e4e6eb;
}

.grouped-by-list__wrapper:last-child {
  border-bottom: none;
}

.grouped-by-list__wrapper span,
.grouped-by-list__empty p {
  color: #666;
}

.grouped-by-list__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;
}

.grouped-by-list__empty-subtitle {
  margin-bottom: 8px;
  font-size: 1rem;
}

.grouped-by-list__empty button {
  width: min-content;
  margin-top: 24px;
  padding: 0 12px;
}
</style>
