(<template>
  <div class="filters-bar">
    <monthly-range-picker v-for="(date, index) in datesList"
                          :key="index"
                          :description-from="date.descriptionFrom"
                          :description-to="date.descriptionTo"
                          :from="date.startDate"
                          :to="date.endDate"
                          class="filters-bar__date-field"
                          @startdatechanged="date.startDate.dateChanged"
                          @enddatechanged="date.endDate.dateChanged" />
    <sk-multiselect :items-list="computedStatuses"
                    :preselected-value="statuses"
                    :default-value="statusText"
                    class="filters-bar__field"
                    @checkboxeschanged="setStatuses" />
    <sk-multiselect :items-list="computedTypes"
                    :preselected-value="types"
                    :default-value="typeText"
                    class="filters-bar__field"
                    @checkboxeschanged="setTypes" />
    <sk-multiselect :items-list="languagesList"
                    :preselected-value="languages"
                    :default-value="languagesText"
                    class="filters-bar__field"
                    @checkboxeschanged="setLanguages" />
    <sk-multiselect :items-list="computedDepartmentsList"
                    :preselected-value="departments"
                    :default-value="departmentsText"
                    class="filters-bar__field"
                    @checkboxeschanged="setDepartments" />
    <sk-multiselect :default-value="qualificationsText"
                    :items-list="qualificationsList"
                    :preselected-value="qualifications"
                    class="filters-bar__field"
                    @checkboxeschanged="setQualifications" />
  </div>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';
  import MonthlyRangePicker from '@/components/statistics/MonthlyRangePicker';

  export default {
    asyncData({store}) {
      let promisesArr = '';

      if (store.getters['UserInfoStore/userIsEnterpriseMember'] && !store.state.StatisticsStore.departmentsList) {
        promisesArr = [...promisesArr, store.dispatch('StatisticsStore/fetchDepartmentsList')];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'monthly-range-picker': MonthlyRangePicker,
    },
    computed: {
      ...mapState('InterpretationStatisticsStore', {
        departmentsList: ({departmentsList}) => departmentsList || [],
        departments: ({filterValues}) => filterValues.departments,
        types: ({filterValues}) => filterValues.types,
        qualifications: ({filterValues}) => filterValues.qualifications,
        statuses: ({filterValues}) => filterValues.statuses,
        languages: ({filterValues}) => filterValues.languages,
        startDate: ({filterValues}) => filterValues.dateFrom,
        endDate: ({filterValues}) => filterValues.dateTo
      }),
      qualificationsList() { return this.$store.getters.interpretationQualifications; },
      languagesList() {
        const fullList = this.$store.state.selectsData.language;
        const assignmentList = fullList?.filter((lang) => lang.id != 3);
        return this.isInterpretationView ? assignmentList : fullList || [];
      },
      departmentsText() { return this.$gettext('Departments'); },
      qualificationsText() { return this.$gettext('Qualification'); },
      typeText() { return this.$gettext('Type'); },
      statusText() { return this.$gettext('Status'); },
      languagesText() { return this.$gettext('Languages'); },
      datesList() {
        return {
          dateRange: {
            ...this.getRangeObject({
              descriptionFrom: '',
              descriptionTo: '',
              startDate: this.startDate,
              endDate: this.endDate,
              mutationFunc: this.setDateRange
            })
          }
        };
      },
      computedDepartmentsList() {
        return this.departmentsList.map((department) => {
          return {id: department.id, name: department.name};
        });
      },
      computedStatuses() {
        return [
          {id: 'published', name: this.$gettext('Received')},
          {id: 'accepted', name: this.$gettext('Confirmed')},
          {id: 'finished', name: this.$gettext('Finished')},
          {id: 'closed', name: this.$gettext('Closed')},
          {id: 'cancelled', name: this.$gettext('Cancelled')}
        ];
      },
      computedTypes() {
        return [
          {id: 'phone', name: this.$gettext('Phone')},
          {id: 'in_person', name: this.$gettext('In-person')},
          {id: 'video', name: this.$gettext('Video')}
        ];
      }
    },
    methods: {
      ...mapMutations('InterpretationStatisticsStore', [
        'setDepartments',
        'setQualifications',
        'setTypes',
        'setStatuses',
        'setLanguages',
        'setDateRange'
      ]),
      getRangeObject({startDate, endDate, mutationFunc, descriptionFrom, descriptionTo}) {
        return {
          descriptionFrom,
          descriptionTo,
          startDate: {
            ...this.getDateObject(startDate),
            dateChanged: (startDateMoment) => {
              const endDateMoment = this.$moment(endDate, 'YYYY-MM-DD');
              this.setStartDate(startDateMoment, endDateMoment, mutationFunc);
            }
          },
          endDate: {
            ...this.getDateObject(endDate),
            dateChanged: (endDateMoment) => {
              const startDateMoment = this.$moment(startDate, 'YYYY-MM-DD');
              this.setEndDate(startDateMoment, endDateMoment, mutationFunc);
            }
          }
        };
      },
      getDateObject(date) {
        return {
          defaultMonth: this.$moment(date, 'YYYY-MM-DD').month() + 1,
          defaultYear: this.$moment(date, 'YYYY-MM-DD').year()
        };
      },
      setStartDate(startDateMoment, endDateMoment, mutationFunc) {
        const startDate = startDateMoment.format('YYYY-MM-DD');
        const endDate = startDateMoment.isBefore(endDateMoment)
          ? endDateMoment.format('YYYY-MM-DD')
          : startDateMoment.add(1, 'months').endOf('month').format('YYYY-MM-DD');
        mutationFunc({startDate, endDate});
      },
      setEndDate(startDateMoment, endDateMoment, mutationFunc) {
        const startDate = this.$moment(startDateMoment).format('YYYY-MM-DD');
        const endDate = endDateMoment.format('YYYY-MM-DD');
        mutationFunc({startDate, endDate});
      }
    }
  };
</script>

<style scoped>
  .filters-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .filters-bar__date-field {
    width: calc(25% - 10px);
    max-width: 300px;
  }

  .filters-bar__field {
    width: calc(13% - 10px);
    max-width: 250px;
    margin: 0 5px 15px;
    background-color: #fff;
  }

  .filters-bar__field:last-child {
    width: calc(20% - 10px);
    margin: 0 0 15px 5px;
  }

  @media (max-width: 767px) {
    .filters-bar__field {
      width: calc(50% - 10px);
      max-width: 100%;
    }
  }

  @media (max-width: 500px) {
    .filters-bar__field {
      width: 100%;
    }
  }
</style>
