(<template>
  <div class="main-chart">
    <h3 class="main-chart__title">{{ $gettext('Overview') }}</h3>
    <div class="main-chart__value">
      <div class="main-chart__value-total">{{ totalCount }}</div>
      <p class="main-chart__value-description">{{ $gettext('Total assignments') }}</p>
    </div>
    <line-chart :options="getLineChartComponents.options"
                :chart-data="getLineChartComponents.chartData"
                class="main-chart__chart-line" />
  </div>
</template>)

<script>
  import LineChart from '@/components/statistics/charts/LineChart';

  export default {
    components: {
      'line-chart': LineChart
    },
    props: {
      statistics: {
        type: Array,
        default: () => []
      },
      totalCount: {
        type: Number,
        default: 0
      }
    },
    computed: {
      getLineChartComponents() {
        if (this.statistics.length) {
          return {
            options: this.getChartOptions(),
            chartData: this.getLineChartData(this.statistics)
          };
        } else return null;
      },
      getStepSize() {
        const total = Number(this.totalCount);

        switch (true) {
          case total < 10: return 1;
          case total < 30: return 3;
          case total < 50: return 5;
          case total < 70: return 8;
          default: return 10;
        }
      }
    },
    methods: {
      getLineChartData(data) {
        const labels = data.map((item) => item.label);
        const datasetData = data.map((item) => item.value || 0);

        const datasets = [{
          label: this.$gettext('assignments'),
          data: datasetData,
          backgroundColor: '#fff',
          borderColor: '#2E90FA',
          borderWidth: 2,
          cubicInterpolationMode: 'monotone',
          lineTension: 0,
          hoverBackgroundColor: '#fff',
          hoverBorderColor: '#2E90FA',
          pointRadius: 0,
          pointHoverRadius: 3,
          fill: false
        }];

        return {labels, datasets};
      },
      getChartOptions() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            mode: 'index',
            intersect: false,
            displayColors: false,
            backgroundColor: '#fff',
            titleFontColor: '#333',
            bodyFontColor: '#666',
            borderColor: '#000',
            borderWidth: 1,
            callbacks: {
              // eslint-disable-next-line func-names
              title: function (tooltipItems, data) {
                const tooltipItem = tooltipItems[0];
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const value = dataset.data[tooltipItem.index];

                return `${value} ${dataset.label}`;
              },
              // eslint-disable-next-line func-names
              label: function (tooltipItem) {
                return tooltipItem.label;
              }
            }
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [{
              display: true,
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: false,
              }
            }],
            yAxes: [{
              display: true,
              gridLines: {
                display: true,
                color: '#DDDFE7',
                borderDash: [8, 5],
              },
              ticks: {
                stepSize: this.getStepSize,
                position: 'left',
                display: true
              },
              scaleLabel: {
                display: false,
              }
            }]
          }
        };
      }
    }
  };
</script>

<style scoped>
.main-chart {
  width: calc(100% - 240px);
  padding: 20px 20px 32px;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
}

.main-chart__title {
  font-size: 1.125rem;
}

.main-chart__value {
  margin: 20px 0 30px 0;
}

.main-chart__value-total {
  font-weight: bold;
  font-size: 2.2rem;
}

.main-chart__value-description {
  margin-top: 6px;
  color: #666;
  font-size: 0.875;
}

.main-chart__chart-line {
  height: 250px;
}
</style>
