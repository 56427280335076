(<template>
  <div class="secondary-charts">
    <div class="secondary-charts__item">
      <h4>{{ $gettext('Type') }}</h4>
      <horizontal-bar-chart :options="horizontalBarChartOptions"
                            :chart-data="horizontalBarChartData" />
    </div>
    <div class="secondary-charts__item">
      <h4>{{ $gettext('Delivered language') }}</h4>
      <div class="doughnut-chart-wrpapper"
           :class="{'doughnut-chart-wrpapper--full-size':largeLanguageList}">
        <doughnut-chart :options="doughnutChartOptions"
                        :chart-data="doughnutChartData"
                        style="min-height: 380px;" />
      </div>
    </div>
  </div>
</template>)

<script>
  import HorizontalBarChart from '@/components/statistics/charts/HorizontalBarChart';
  import DoughnutChart from '@/components/statistics/charts/DoughnutChart';

  export default {
    components: {
      'horizontal-bar-chart': HorizontalBarChart,
      'doughnut-chart': DoughnutChart
    },
    props: {
      statsBySessionType: {
        type: Array,
        default: () => []
      },
      statsByLanguage: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        colors: ['#246DFF', '#FAD623', '#54BF54', '#F5771D', ' #FF3624', '#104A81', '#ff0044', '#00cc77', '#FF69B4', '#800080', '#8B4513', '#20B2AA', '#FA8072'],
      };
    },
    computed: {
      largeLanguageList() { return this.doughnutChartData.labels.length >= 18; },
      typeLabels() {
        return {
          phone: this.$gettext('Phone'),
          in_person: this.$gettext('In person'),
          video: this.$gettext('Video')
        };
      },
      horizontalBarChartOptions() {
        return {
          barThickness: 20,
          borderWidth: 1,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: true,
                drawOnChartArea: true,
                color: '#DDDFE7',
                lineWidth: 1,
                borderDash: [8, 5]
              },
              ticks: {
                beginAtZero: true,
              }
            }],
            yAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
        };
      },
      doughnutChartOptions() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            mode: 'index',
            intersect: true
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          legend: {
            position: 'right',
            labels: {
              padding: 10,
              boxWidth: 14,
              fontStyle: 'bold',
              fontColor: '#333',
              fontSize: 12,
              boxHeight: 14,
              borderRadius: 5
            }
          },
          cutoutPercentage: '75',
          scales: {xAxes: [{display: false}], yAxes: [{display: false}]}
        };
      },
      horizontalBarChartData() {
        return this.getHorizontalBarChartData(this.statsBySessionType);
      },
      doughnutChartData() {
        return this.getDoughnutChartData(this.statsByLanguage);
      }
    },
    methods: {
      getHorizontalBarChartData(data) {
        const labels = data.map((item) => this.typeLabels[item.label]);
        const datasetData = data.map((item) => item.value || 0);

        return {
          labels,
          datasets: [{
            data: datasetData,
            backgroundColor: '#246DFF',
            hoverBackgroundColor: '#246DFF',
            hoverBorderColor: '#000',
            hoverBorderWidth: 1.5,
            barBorderRadius: 4,
            barThickness: 16
          }]
        };
      },
      getDoughnutChartData(data) {
        const total = data.reduce((sum, item) => sum + (item.value || 0), 0);
        const threshold = total * 0.0129;

        let otherTotal = 0;
        const groupedData = data.reduce((acc, item) => {
          const value = item.value || 0;
          if (value >= threshold) {
            acc.push({...item, value});
          } else {
            otherTotal += value;
          }
          return acc;
        }, []);

        if (otherTotal > 0) {
          groupedData.push({label: this.$gettext('Other'), value: otherTotal, otherSection: true});
        }

        return {
          labels: groupedData.map((item) => `${item.label} (${(((item.value || 0) / total) * 100).toFixed(2)}%)`),
          datasets: [{
            data: groupedData.map((item) => item.value),
            backgroundColor: groupedData.map((item) => (item.otherSection ? '#D0D5DD' : this.colors[groupedData.indexOf(item) % this.colors.length]))
          }]
        };
      }
    }
  };
</script>

<style scoped>
.secondary-charts {
  display: flex;
  gap: 24px;
  margin: 24px 0;
  color: #333;
}

.secondary-charts__item {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
}

.secondary-charts__item:first-child {
  width: calc(33% - 12px);
  padding: 20px 20px 32px;
}

.secondary-charts__item:last-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(67% - 12px);
  padding: 20px;
}

.secondary-charts__item h4 {
  margin-right: auto;
  margin-bottom: 20px;
  font-size: 1.25rem;
}

.doughnut-chart-wrpapper {
  width: 400px;
  height: 380px;
}

.doughnut-chart-wrpapper--full-size {
  width: 90%;
}
</style>
