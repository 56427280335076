(<template>
  <section class="interpretation-statistics">
    <div class="interpretation-statistics__header">
      <div class="interpretation-statistics__subtitles">
        <h4 class="interpretation-statistics__subtitle">{{ $gettext('Interpretation statistics') }}</h4>
        <router-link :to="translationStatisticsLink"
                     class="interpretation-statistics__subtitle">{{ translationStatisticsText }}</router-link>
      </div>
      <button class="sk-btn sk-btn--white interpretation-statistics__header-export-button"
              @click="openExportModal">{{ $gettext('Export') }}</button>
    </div>
    <interpretation-filter-bar />
    <article v-if="statistics.totalCount">
      <div class="interpretation-statistics__main-board">
        <overview-chart :statistics="getGroupedByScheduledAt"
                        :total-count="getTotalCount" />
        <div class="interpretation-statistics__status-block status-block">
          <h4 class="status-block__subtitle">{{ $gettext('Status') }}</h4>
          <div v-for="(status, index) in statusesBar"
               :key="index"
               class="status-block__item">
            <p class="status-block__item-text"
               :class="status.iconClass">{{ status.name }}</p>
            <span class="status-block__item-count">{{ status.value }}</span>
          </div>
        </div>
      </div>
      <detailed-charts :stats-by-session-type="getGroupedBySessionType"
                       :stats-by-language="getGroupedByLanguage" />
      <div class="interpretation-statistics__grouped-by">
        <grouped-by-list-block :grouped-by-list="getGroupedByDepartment"
                               :subtitle="$gettext('Departments')"
                               :subtitle-empty="$gettext('No departments')"
                               :description-empty="$gettext('There are no departments to show')" />
        <grouped-by-list-block :grouped-by-list="getGroupedByQualification"
                               :subtitle="$gettext('Delivered qualification')"
                               :subtitle-empty="$gettext('No qualification')"
                               :description-empty="$gettext('There are no qualifications to show')" />
      </div>
    </article>
    <article v-else
             class="interpretation-statistics__empty">
      <h4 class="interpretation-statistics__empty-title">{{ $gettext('Overview') }}</h4>
      <div class="interpretation-statistics__empty-image"></div>
      <h4 class="interpretation-statistics__empty-subtitle">{{ $gettext('No statistics') }}</h4>
      <p class="interpretation-statistics__empty-text">{{ emptyStatisticsText }}</p>
      <div class="interpretation-statistics__buttons">
        <button class="sk-btn sk-btn--white interpretation-statistics__buttons-item"
                @click="clearFilters">{{ $gettext('Reset filters') }}</button>
        <button class="sk-btn sk-btn--white interpretation-statistics__buttons-item"
                @click="reloadPage">{{ $gettext('Reload') }}</button>
      </div>
    </article>
  </section>
</template>)

<script>
  import {mapMutations, mapState, mapActions, mapGetters} from 'vuex';
  import InterpretationFilterBar from '@/components/statistics/interpretation_statistics/InterpretationFilterBar';
  import GropedByListBlock from '@/components/statistics/interpretation_statistics/GroupedByListBlock';
  import OverviewChart from '@/components/statistics/interpretation_statistics/OverviewChart';
  import DetailedCharts from '@/components/statistics/interpretation_statistics/DetailedCharts';

  export default {
    asyncData({store}) {
      let promisesArr = [];

      if (store.getters['UserInfoStore/userIsEnterpriseMember'] && !store.state.InterpretationStatisticsStore.departmentsList) {
        promisesArr = [...promisesArr, store.dispatch('InterpretationStatisticsStore/fetchDepartmentsList')];
      }

      if (!store.state.InterpretationStatisticsStore.statistics) {
        promisesArr = [...promisesArr, store.dispatch('InterpretationStatisticsStore/fetchInterpretationStatistics')];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'interpretation-filter-bar': InterpretationFilterBar,
      'grouped-by-list-block': GropedByListBlock,
      'overview-chart': OverviewChart,
      'detailed-charts': DetailedCharts
    },
    computed: {
      ...mapState('InterpretationStatisticsStore', {
        statistics: (state) => state.statistics,
        filterValues: (state) => state.filterValues,
      }),
      ...mapGetters('InterpretationStatisticsStore', [
        'getGroupedByJobStatus',
        'getGroupedByDepartment',
        'getGroupedByQualification',
        'getGroupedByScheduledAt',
        'getGroupedBySessionType',
        'getGroupedByLanguage',
        'getTotalCount'
      ]),
      emptyStatisticsText() { return this.$gettext('Looks like there is nothing to show.'); },
      translationStatisticsText() { return this.$gettext('Translation statistics'); },
      translationStatisticsLink() { return this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'translation'}, params: {chartId: 'line_type'}}); },
      statusesBar() {
        const statuses = [
          {key: 'published', name: this.$gettext('Received'), iconClass: 'status-icon--received'},
          {key: 'accepted', name: this.$gettext('Confirmed'), iconClass: 'status-icon--confirmed'},
          {key: 'finished', name: this.$gettext('Finished'), iconClass: 'status-icon--finished'},
          {key: 'closed', name: this.$gettext('Closed'), iconClass: 'status-icon--closed'},
          {key: 'cancelled', name: this.$gettext('Cancelled'), iconClass: 'status-icon--cancelled'}
        ];

        const statusesWithValues = statuses.map((status) => {
          const found = this.getGroupedByJobStatus.find((item) => item.label === status.key);
          return {
            ...status,
            value: found ? found.value : 0
          };
        });

        return statusesWithValues.sort((a, b) => {
          const aIsFiltered = this.filterValues.statuses.includes(a.key);
          const bIsFiltered = this.filterValues.statuses.includes(b.key);

          if (aIsFiltered && !bIsFiltered) return -1;
          if (!aIsFiltered && bIsFiltered) return 1;

          return 0;
        });
      }
    },
    watch: {
      filterValues: {
        deep: true,
        handler() {
          this.refetchData();
        }
      }
    },
    methods: {
      ...mapMutations('InterpretationStatisticsStore', ['clearStore', 'clearFilters']),
      ...mapActions('InterpretationStatisticsStore', ['fetchInterpretationStatistics', 'downloadStatistics']),
      exportStatistics(format) {
        this.downloadStatistics(format).then((csvData) => {
          const blob = new Blob([csvData], {type: 'text/csv'});
          this.getFileName(format);

          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = this.getFileName(format);
          downloadLink.click();
        });
      },
      getFileName(format) {
        const fileNames = {
          overview_report: 'Tolkestatistikk (alle).csv',
          lov_report: 'Tolkemonitor LOV.csv',
          sprak_report: 'Tolkemonitor SPRAK.csv'
        };

        return fileNames[format];
      },
      openExportModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'export-statistics-modal',
          data: {
            title: this.$gettext('Export CSV'),
            context: this,
            modalCallback: this.exportStatistics,
            btnTexts: {
              submitBtnText: this.$gettext('Export'),
              cancelBtnText: this.$gettext('Cancel')
            },
          }
        });
      },
      refetchData() {
        this.$store.commit('GlobalStore/startProgress');
        this.fetchInterpretationStatistics().then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      },
      reloadPage() {
        window.location.reload();
      }
    },
    beforeRouteLeave(to, from, next) {
      this.clearStore();
      next();
    },
  };
</script>

<style scoped>
.interpretation-statistics {
  flex-direction: column;
  width: 1140px;
  max-width: 100%;
  margin: 0 auto;
  padding: 45px 0;
}

.interpretation-statistics__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.interpretation-statistics__subtitles {
  display: flex;
}

.interpretation-statistics__subtitle:first-child {
  border-bottom: 5px solid var(--color-primary);
  color: var(--color-primary);
}

.interpretation-statistics__subtitle {
  padding: 10px 10px 5px;
  font-weight: bold;
  font-size: 1.25rem;
}

.interpretation-statistics__header-export-button {
  width: auto;
  padding-right: 15px;
  padding-left: 35px;
  background-image: url(~@assets/imgs/undefined_imgs/export_excel_doc_icon_black.svg);
  background-position: 7% 50%;
  background-size: 22px auto;
  background-repeat: no-repeat;
  font-weight: 500;
}

.filter-bar {
  padding: 16px 20px;
  background-color: var(--col-white);
}

.filter-bar__field {
  width: calc(38% - 10px);
}

.interpretation-statistics__main-board {
  display: flex;
}

/* status block */
.status-block {
  width: 220px;
  margin-left: auto;
  padding: 20px 24px 16px 24px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
}

.status-block__subtitle {
  margin-bottom: 24px;
  color: #333;
  font-size: 1.2rem;
}

.status-block__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #dddfe7;
  border-radius: 8px;
  cursor: default;
}

.status-block__item:nth-child(3),
.status-block__item:nth-child(5) {
  background-color: #f4f5f7;
}

.status-block__item-text {
  padding-left: 24px;
  background-position: 0 center;
  background-repeat: no-repeat;
  font-weight: bold;
}

.status-icon--received {
  background-image: url(~@assets/imgs/statistics_icons/statistic_status_received_icon.svg);
}

.status-icon--confirmed {
  background-image: url(~@assets/imgs/statistics_icons/statistic_status_confirmed_icon.svg);
}

.status-icon--finished {
  background-image: url(~@assets/imgs/statistics_icons/statistic_status_finished_icon.svg);
}

.status-icon--closed {
  background-image: url(~@assets/imgs/statistics_icons/statistic_status_closed_icon.svg);
}

.status-icon--cancelled {
  background-image: url(~@assets/imgs/statistics_icons/statistic_status_cancelled_icon.svg);
}

.status-block__item-count {
  color: #666;
}

/* empty statistics */
.interpretation-statistics__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  padding: 20px 24px 50px 24px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
}

.interpretation-statistics__empty-title {
  width: 100%;
  margin-bottom: 60px;
  font-size: 18px;
}

.interpretation-statistics__empty-image {
  width: 96px;
  height: 96px;
  background-image: url(~@assets/imgs/undefined_imgs/dashboard_icon.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.interpretation-statistics__empty-subtitle {
  margin: 24px 0 8px 0;
  font-size: 20px;
}

.interpretation-statistics__empty-text {
  width: 400px;
  color: var(--black-500);
  text-align: center;
}

.interpretation-statistics__buttons {
  display: flex;
  gap: 8px;
  margin-top: 24px;
}

.interpretation-statistics__buttons-item {
  width: min-content;
  padding: 0 12px;
}

.interpretation-statistics__grouped-by {
  display: flex;
  gap: 24px;
}

@media (max-width: 767px) {
  .interpretation-statistics {
    padding: 10px;
  }
}
</style>
